.container {
  max-width: 960px;
  margin: 0 auto;
}

.center-text {
  font-weight: 700;
  /*  */
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #0e0e2c;
  /* border-bottom: 4px solid   #f3692e; */
}

.center-text-line {
  text-align: center;
  width: 384px;
  height: 4px;
  background: #f3692e;
  border-radius: 8px;
  margin: auto;
}

.carousel-text-line {
  text-align: center;
  width: 515px;
  height: 4px;
  background: #f3692e;
  border-radius: 8px;
  margin: auto;
}

.center-text_3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #fff;
}

.center-text-line_3 {
  width: 170px;
  height: 3px;
  background: #f3692e;
  border: none;
  border-radius: 8px;
  margin: auto;
}

.center-text_4 {
  font-weight: 700;
  font-size: 45px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #000;
}

.center-text-line_4 {
  width: 280px;
  height: 3px;
  background: #f3692e;
  border: none;
  border-radius: 8px;
  margin: auto;
}

.center-text_5 {
  font-weight: 700;
  font-size: 43px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #000;
}

.center-text-line_5 {
  width: 300px;
  height: 3px;
  background: #f3692e;
  border: none;
  border-radius: 8px;
  margin: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .umrah-head-title {
    font-size: 60px;
    font-weight: 600;
    margin-left: 10px;
    line-height: 55px;
  }
  .umrah-img {
    display: none;
  }
  .icons-nav {
    margin-left: 92px;
    margin-right: -31px;
  }
  .header-content {
    padding-left: 17px !important;
  }
  .first-text {
    font-size: 14px !important;
    width: 300px !important;
  }
  .main-text {
    font-size: 45px !important;
    width: 382px !important;
    text-align: center;
  }
  .last-text {
    width: 366px !important;
    text-align: center;
  }
  .header-content button {
    width: 120px !important;
    height: 48px !important;
    font-size: 14px !important;
    margin-left: 110px;
  }
  .center-text {
    font-size: 35px !important;
  }
  .center-text-line {
    width: 281px !important;
  }
  .card_content {
    margin-top: 20px !important;
    padding-left: 15px !important;
    grid-template-columns: repeat(2, minmax(184px, 1fr)) !important;
  }
  .card-title {
    font-size: 24px !important;
    margin-left: 8px;
  }
  .card-image img {
    width: 170px !important;
  }
  .example_Section {
    height: 100% !important;
    width: 100%;
    display: flex !important  ;
    align-items: center;
    justify-content: center !important;
  }
  .home_umrah_content {
    padding-left: 12px !important;
  }
  .home_urmrah_title {
    font-size: 41px !important;
    width: 400px !important;
  }
  .home_urmrah_content_text p {
    margin: 96px 0 20px 0 !important;
  }

  .home_urmrah_content_text button {
    margin-top: 80px !important;
    width: 135px;
    height: 56px;
  }
  .home_carousel {
    padding-left: 17px !important;
    padding-right: 0 !important;
    padding-top: 30px !important;
    height: 647px;
  }

  .center-text {
    font-size: 32px !important;
    /* width: 350px !important; */
  }

  .carousel-text-line {
    width: 368px !important;
  }

  .center-text_3 {
    font-size: 32px !important;
  }

  .center-text-line_3 {
    width: 226px !important;
    margin-bottom: -89px;
  }
  .center-text_4 {
    font-size: 33px !important;
  }
  .center-text-line_4 {
    width: 211px !important;
  }
  .home_about-content {
    grid-template-rows: repeat(1, minmax(200px, 1fr));
    padding-top: 25px;
    padding-left: 27px;
  }
  .about-image_1 {
    width: 338px;
    height: 201px;
  }
  .about-img {
    width: 160px;
    height: 160px;
    margin-top: -60px;
    display: flex;
  }
  .about-text {
    margin-top: 10px;
    width: 345px !important;
  }
  .about-text p {
    font-size: 14px !important;
  }
  .about-text button {
    margin-top: 30px;
    width: 155px;
    height: 50px;
  }

  .home_contact_content {
    text-align: left;
    padding-top: 90px;
    padding-left: 24px;
    padding-right: 0px;
  }
  .center-text_5 {
    font-size: 33px !important;
  }
  .center-text-line_5 {
    width: 223px !important;
  }
  .home-contect-items {
    margin-top: 20px;
  }

  .footer {
    padding-left: 28px !important;
    padding-right: 0 !important;
  }
  .city-content-main p {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    width: 300px !important;
    color: #f3692e !important;
  }
  .city-head-title {
    font-size: 50px !important;
    line-height: 50px !important;
    width: 400px !important ;
    letter-spacing: -0.04em !important;
    color: #ffffff;
    margin-left: 10px;
    /* text-align: center !important; */
  }
  .city-head-text {
    font-size: 20px !important;
    width: 300px !important;
    margin-top: 50px !important;
    text-align: justify !important;
    margin-left: 20px !important;
  }
  .city-content-main h1 {
    font-size: 27px !important;
    text-align: center !important;
    width: 320px !important;
    margin-left: 20px !important;
  }

  .city-content-main p {
    margin-top: 20px;
    width: 350px !important;
    text-align: justify !important;
    margin-left: 20px !important;
  }
  .cites-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .city-cards {
    margin-top: 20px !important;
    grid-template-columns: repeat(2, minmax(184px, 1fr)) !important;
  }
  .city-title {
    font-size: 27px !important;
    margin-left: 10px !important;
  }
  .about-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .about-content ul li {
    width: 370px;
    text-align: justify;
    margin-left: 5px;
  }
  .about-content-main h1 {
    text-align: center;
    font-size: 23px;
    width: 350px;
    color: #f3692e;
    font-family: cursive;
  }

  iframe {
    width: 380px;
    margin-left: 7px;
  }
  .location {
    margin: -15px 0 10px 150px !important;
  }
  .about-content img {
    min-width: 380px;
    margin-left: 5px;
  }
  .cites-content {
    /* display: flex; */
    /* flex-direction: column-reverse; */
  }
  .forma {
    width: 380px !important;
    height: 392px;
    background: #ffffff;
    border-radius: 16px;
    margin-left: 0 !important;
  }
  form input {
    margin-top: 5px;
    background: #f6f8fa;
    outline: none;
    border: 1px solid #eff3f8;
    border-radius: 5px;
    width: 340px !important;
  }
  form div {
    margin-bottom: 0;
  }
  form {
    padding: 40px;
  }
  .formtext {
    width: 380px !important;
    margin-top: 120px;
  }
  .question {
    width: 380px !important;
  }
  .questionfrom {
    display: none;
  }
  .locations {
    margin-top: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.04em;
    color: #f3692e;
    text-align: center;
  }
  .contactbutton {
    margin-left: 0px !important;
    height: 40px !important;
    width: 320px !important;
  }

  .detaled-title {
    font-size: 37px;
    text-align: center;
    font-weight: bold;
  }
  .detaled-title-2 {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
  }
  .detailed-about {
  }
  .text {
    font-size: 19px;
  }
  .detailed-items {
    padding: 15px;
  }
  .travel-about {
    margin-left: 18px;
  }
  .text-wont {
    font-size: 17px;
  }
  .mleft {
    margin-left: 110px;
  }
  .mleft-2 {
    margin-left: 20px;
  }
  .youtube-video {
    width: 350px;
    height: 250px;
  }
  .flex-images-items img {
    width: 360px;
    margin-top: 20px;
  }
  .location-place {
    width: 350px;
    height: 400px;
  }
  .actions {
    margin-left: 44px;
  }

  .umrah-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .umrah-first-title {
    font-size: 38px;
    text-align: center;
    font-weight: bold;
  }
  .booking-title {
    font-weight: 500;
    font-size: 18px;
  }
  .band-btn {
    background: #f36326;
    border-radius: 8px;
    width: 110px;
    height: 50px;
    color: #fff;
    letter-spacing: 0.005em;
    font-size: 16px;
    margin-left: 27px;
    margin-top: 10px;
  }
  .home_contact {
    margin-top: 140px;
  }
  .home_galereya {
    height: 100vh;
  }
  .home_galereya_content {
    padding-top: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .icons-nav {
    margin-left: 50px;
    /* margin-right: -31px; */
  }
  .location-place {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
  }
  .flex-images-items {
    display: flex;
  }
  .flex-images-items img {
    width: 240px;
  }
  .mleft {
    margin-left: 9rem;
  }
  .mleft-2 {
    margin-left: 2rem;
  }
  .mbottom {
    margin-bottom: 0.5rem;
  }
  .travel-about {
    margin-left: 100px;
  }
  .text {
    font-size: 24px;
    line-height: 32px;
  }
  .detaled-title {
    font-size: 3rem;
  }
  .detailed-about {
    margin-left: 170px;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .home_about-content {
    margin-top: 20px;
    display: grid;
    /* grid-template-rows: repeat(3, minmax(0, 1fr)); */
    grid-auto-flow: column;
    padding-top: 50px;
    padding-left: 120px;
    padding-right: 120px;
  }
  .about-image_1 {
    grid-column: span 2 / span 2;
    width: 550px;
    height: 280px;
  }
  .about-img {
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
    display: flex;
    width: 300px;
    height: 265px;
    margin-top: -60px;
  }
  .about-text {
    grid-row: span 3 / span 3;
  }
  .about-text button {
    margin-top: 25px;
    width: 162px;
    height: 56px;
  }
  .home_contact_content {
    text-align: left;
    padding-top: 50px;
    padding-left: 120px;
    padding-right: 120px;
    display: flex;
  }
  .home-contect-items {
    margin-bottom: 2.75rem;
    margin-left: 7rem;
  }
  .footer-content {
    display: flex;
  }
  .detialed-content {
    gap: 24px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    display: grid;
    margin-top: 3rem;
  }
  .flex-images {
    display: flex;
  }
  .text-wont {
    margin-bottom: 1rem;
  }
  .umrah-content {
    padding-left: 120px;
    padding-right: 120px;
    display: flex;
  }
  .umrah-first-title {
    font-size: 48px;
    text-align: left;
    font-weight: bold;
    margin-top: 30px;
  }
  .booking-title {
    font-weight: 500;
    font-size: 20px;
  }
  .band-btn {
    background: #f36326;
    border-radius: 8px;
    width: 110px;
    height: 50px;
    /* padding: 18px 32px; */
    color: #fff;
    letter-spacing: 0.005em;
    font-size: 16px;
    margin-left: 27px;
    margin-top: 10px;
  }
  .per-person {
    width: 1030px;
  }
  .per-person-line {
    width: 950px;
    margin-left: 25px;
  }
  .home_contact {
    margin-top: 140px;
  }
  .home_galereya {
    height: 700px;
  }
  .home_galereya_content {
    padding-top: 20px;
  }
  .header_section-two {
    height: 750px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.question-header {
  background: url(../../assets/Images/questions.png) no-repeat center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  z-index: -1;
}

.question-bc {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) -6.11%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 170px;
}

.question-content-main {
  height: 500px;
}

.question-content-main h1 {
  font-weight: 700;
  font-size: 41px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #0e0e2c;
  text-align: center;
  margin: 30px;
}

/* Accardion css */
.question {
  padding: 1rem 1.5rem;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  width: 820px;
  min-height: 65px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.question header {
  margin-bottom: 50px;
}

.question h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #0d1136;
}

.question p {
  color: var(--clr-grey-3);
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.question header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  background: transparent;
  border-color: transparent;
  width: 2rem;
  height: 2rem;
  background: var(--clr-grey-special);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr-red-special);
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 2rem;
}

.question-title {
  cursor: pointer;
}
.question-header {
  background: url(../../assets/Images/questions.png) no-repeat center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  z-index: -1;
}

.question-bc {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) -6.11%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 170px;
}

.question-content-main {
  height: 500px;
}

.question-content-main h1 {
  font-weight: 700;
  font-size: 41px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #0e0e2c;
  text-align: center;
  margin: 30px;
}

/* Accardion css */
.question {
  padding: 1rem 1.5rem;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  width: 820px;
  min-height: 65px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.question header {
  margin-bottom: 50px;
}

.question h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #0d1136;
}

.question p {
  color: var(--clr-grey-3);
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.question header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  background: transparent;
  border-color: transparent;
  width: 2rem;
  height: 2rem;
  background: var(--clr-grey-special);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr-red-special);
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 2rem;
}

.question-title {
  cursor: pointer;
}
